.icon {
    color: #e9ecef;
    margin-inline-end: 20px;
    cursor: pointer;
}

.icon :hover {
    color: #4478A6;
}

.contact-icon {
    margin-inline-end: 20px;
    cursor: pointer;
}

.contact-icon :hover{
    color: #4478A6;
}
