.button {
    border-radius: 0;
    color: #393f4d !important;
    background-color: white !important;
    border-color: white !important;
    font-family: "Raleway";
}

.button:hover {
    border: 2;
    border-color: #393f4d !important;
    border-radius: 0px, 0px, 0px, 0px;
}

.button:focus,.button:active {
    outline: none !important;
    box-shadow: none !important;
 }

.selected-button {
    border-radius: 0;
    color: #393f4d !important;
    background-color: white !important;
    border-color: #393f4d !important;
    font-family: "Raleway";
}

.selected-button:hover {
    border: 2;
    border-color: #393f4d !important;
    border-radius: 0px, 0px, 0px, 0px;
}

.selected-button:focus,.selected-button:active {
    outline: none !important;
    box-shadow: none !important;
 }

 .heading {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
  }
  
  .subheading {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
  }