.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0C1226;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #4478A6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.row-header{
  margin:0px auto;
  padding:0px auto;
}

.row-content {
  margin:0px auto;
  padding: 50px 0px 50px 0px;
  border-bottom: 1px ridge;
  min-height:400px;
}

.footer{
  background-color: #0C1226;
  margin:0px auto;
  padding: 20px 0px 20px 0px;
}

/* .jumbotron {
  padding:70px 30px 70px 30px;
  margin:0px auto;
  color:#393f4d!important;
} */

.navbar-dark {
  /* background-color: #393f4d; */
  background-color: #0C1226
}

.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.image {
  width: 150;
  height: 150;
  border-radius: 150 / 2;
  overflow: "hidden";
  border-width: 3;
}

.footer-email {
  cursor: pointer;
  margin-top: '10px';
  font-family: "Raleway";
  font-weight: 300;
}

.footer-email:hover {
  color: #4478A6 !important;
}

.contact {
  cursor: pointer;
  font-family: "Source Sans Pro";
}

.contact:hover {
  color: #4478A6 !important;
}

.resume-heading {
  margin-right: 20px;
  font-family: "Raleway";
  font-weight: 400;
  cursor: pointer;
  font-stretch: extra-expanded
}

.resume-heading:hover{
  color: #4478A6
}

.course {
  cursor: pointer;
}

.course:hover{
  color: #4478A6
}

.dashed {
  background-image: linear-gradient(to right, blue 75%, transparent 75%);
  background-position: 0 1.04em;
  background-repeat: repeat-x;
  background-size: 8px 3px;
}
